@import "@/assets/style/_index.scss";





















































































.qrcode {
  padding: $padding;
  width: 80%;
  margin: 60px auto 0 auto;
  > .inner {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding-top: 100%;

    > .img-obj {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: $padding;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.qrcode-desc {
  text-align: center;
  color: $color-text-minor;
}

.ctrl {
  padding: $padding-big * 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  > .btn {
    padding: $padding;
    + .btn {
      margin-top: $margin;
    }
  }
}
