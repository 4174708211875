@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.container[data-v-407b9780] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 2rem;
}
.container > .img-obj[data-v-407b9780] {
  width: 4rem;
  margin-bottom: 0.5rem;
  opacity: 0.2;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.qrcode[data-v-0229230e] {
  padding: 0.2rem;
  width: 80%;
  margin: 8vw auto 0 auto;
}
.qrcode > .inner[data-v-0229230e] {
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: 0.667vw;
  padding-top: 100%;
}
.qrcode > .inner > .img-obj[data-v-0229230e] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.2rem;
}
.qrcode > .inner > .img-obj > img[data-v-0229230e] {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.qrcode-desc[data-v-0229230e] {
  text-align: center;
  color: #999;
}
.ctrl[data-v-0229230e] {
  padding: 0.9rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.ctrl > .btn[data-v-0229230e] {
  padding: 0.2rem;
}
.ctrl > .btn + .btn[data-v-0229230e] {
  margin-top: 0.2rem;
}

/*# sourceMappingURL=other.05063058.css.map*/